<template>
  <section id="card-images">
    <h4 class="my-1 text-center font-weight-bolder">
      Exemples d’intégrations de nos API
    </h4>
    <h5 class="mt-1 mb-2 text-center">
      Découvrez des exemples d'intégrations et d'usages de nos APIs.
    </h5>
    <b-row class="justify-content-md-center">
      <b-col cols="4">
        <b-card
          :img-src="`${publicPath}` + `images/products/decimmo_banner.png`"
          bg-variant="secondary"
          overlay
          text-variant="white"
          img-alt="card img"
          body-class="bg-overlay"
          img-height="155px"
          class="cursor-pointer mooving-card"
          @click="
            $router.push({
              name: 'realestate-searcher',
            })
          "
        >
          <b-card-title class="text-white"> Portail d'annonces </b-card-title>
          <b-card-text class="font-weight-bolder" style="width: 230px">
            Visualisez l'ensemble des annonces disponnibles avec cet exemple
            d'intégration de l'API RealEstate.
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card
          :img-src="`${publicPath}` + `images/products/estimator_banner.png`"
          bg-variant="secondary"
          overlay
          text-variant="white"
          img-alt="card img"
          body-class="bg-overlay"
          img-height="155px"
          class="cursor-pointer mooving-card"
          @click="
            $router.push({
              name: 'estimate-demo',
            })
          "
        >
          <b-card-title class="text-white"> Estimateur de bien </b-card-title>
          <b-card-text class="font-weight-bolder" style="width: 230px">
            Estimez rapidement et efficacement la valeur d'un bien immobilier
            avec cet exemple d'intégration de l'API Estimation.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
