<template>
  <b-row>
    <!-- <b-col cols="12" class="mt-2">
      <autoplay-products />
    </b-col> -->
    <b-col cols="12">
      <tools-cards />
    </b-col>
    <b-col cols="12">
      <apis-cards />
    </b-col>
    <b-col cols="12">
      <examples-cards />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { BRow, BCol } from "bootstrap-vue";

import ToolsCards from "./components/ToolsCards.vue";
import ExamplesCards from "./components/ExamplesCards.vue";
import ApisCards from "./components/ApisCards.vue";

export default {
  components: {
    BRow,
    BCol,

    // Vue Custom Components
    ToolsCards,
    ExamplesCards,
    ApisCards,
  },
  mixins: [PageBoxed],
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    };
  },
};
</script>
