<template>
  <section id="card-images">
    <!-- <b-row class="justify-content-md-center text-center">
      <b-col cols="7">
        <hr class="my-50 mx-5" />
        <blockquote class="blockquote mb-0 font-weight-bolder">
          <p>
            Nous proposons de nombreux services spécialisés dans la création
            d'outils numériques dédiés à l'immobilier: annonces neuf & ancien,
            estimation de bien, analyse détaillée de secteur et du marché
            immobilier. Cadre de Vie accompagne ses clients dans la mise en
            place de parcours d’achat, d’investissement ou de « marchés cachés »
            entre clients.
          </p>
          <footer class="blockquote-footer">
            <small class="font-weight-bolder"> Cadre de Vie </small>
          </footer>
        </blockquote>
        <hr class="my-50 mx-5" />
      </b-col>
    </b-row> -->
    <h4 class="my-1 text-center font-weight-bolder">Nos Outils</h4>
    <h5 class="mt-1 mb-2 text-center">
      Découvrez nos outils hébergés et utilisables directement par abonnement
      sans intégrations préalables.
    </h5>
    <b-row class="justify-content-md-center">
      <b-col cols="8">
        <b-card
          :img-src="`${publicPath}` + `images/products/decimmo.jpg`"
          overlay
          text-variant="white"
          img-alt="card img"
          body-class="bg-overlay"
          img-height="170px"
          class="cursor-pointer mooving-card"
          @click="
            $router.push({
              name: 'decimmo-searcher',
            })
          "
        >
          <b-card-title class="text-white">
            Décision Immo
            <b-badge pill variant="success">new</b-badge>
          </b-card-title>
          <b-card-text class="font-weight-bolder" style="width: 210px">
            Recherchez, comparez et qualifiez un programme immobilier neuf ou
            son futur emplacement. Sauvegardez & diffusez vos rapports
            d'analyse.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BBadge,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
