<template>
  <section id="card-images">
    <h4 class="my-1 text-center font-weight-bolder">
      Nos APIs de données immobilières
    </h4>
    <h5 class="mt-1 mb-2 text-center">
      Découvrez nos APIs intégrables dans vos sites web et vos outils métier.
    </h5>
    <b-row class="justify-content-md-center mx-5">
      <b-col cols="4" v-for="product in swiperData" :key="product.name">
        <b-card
          :img-src="`${publicPath}` + `images/products/` + `${product.banner}`"
          bg-variant="primary"
          overlay
          text-variant="white"
          img-alt="card img"
          body-class="bg-overlay"
          img-height="180px"
          class="cursor-pointer mooving-card"
          @click="
            $router.push({
              name: product.code + '-redoc',
              query: { jsonName: product.documentation_link },
            })
          "
        >
          <b-card-title class="text-white"> {{ product.name }} </b-card-title>
          <b-card-text class="font-weight-bolder" style="width: 160px">
            {{ product.description }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    swiperData() {
      return this.$store.state.products.products.filter(
        (product) => product.category === "API"
      );
    },
  },
  mounted() {
    this.$store.dispatch("products/fetchProducts");
  },
};
</script>
